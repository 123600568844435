$(function ($) {
  const elements = {
    sliderFeedback: $('.feedback_list'),
    sliderBenefits: $('.benefits_list')
  };
  let sliderStates = false;

  function isMobileView() {
    return window.innerWidth < 768;
  }

  function setEventHandler() {

    $(window).on('resize', function () {
      if (!isMobileView()) {
        sliderStates = false;
        if (elements.sliderFeedback[0].classList.contains("slick-initialized")) {
          elements.sliderFeedback.slick('unslick');
        }
        if (elements.sliderBenefits[0].classList.contains("slick-initialized")) {
          elements.sliderBenefits.slick('unslick');
        }
      }

      if (isMobileView() && !sliderStates) {
        initSliders();
      }
    });
  }

  function initSliders() {
    if (isMobileView()) {
      sliderStates = true;

      elements.sliderFeedback.slick({
        adaptiveHeight: true,
        arrows: false,
        autoplay: false,
        cssEase: 'linear',
        dots: true,
        fade: true,
        speed: 500
      });

      elements.sliderBenefits.slick({
        adaptiveHeight: true,
        arrows: false,
        autoplay: false,
        cssEase: 'linear',
        dots: true,
        fade: true,
        speed: 500
      });
    }
  }

  (function init() {
    initSliders();
    setEventHandler();
  }());
}(window.jQuery));
